<template>
  <div class="cr-project-member-toolbar">
    <Toolbar
      :items="items"
      :checkbox="checkbox"
      :indeterminate="indeterminate"
      @update:checkbox="check"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-project-member-toolbar::v-deep {
  position: relative;
  height: 40px;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
  .v-input.v-input--checkbox {
    top: 8px;
    left: 8px;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import Toolbar from "@/commons/views/toolbar/Toolbar";

export default {
  components: { Toolbar },
  props: {
    tab: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: () => []
    },
    selectedRows: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("flowProjects", { members: "managedMembers" }),
    filteredMembers() {
      const { value: joinStatus } = this.tabs[this.tab];

      if (joinStatus === "ALL") return this.members;
      return this.members.filter(m => m.joinStatus === joinStatus);
    },
    checkbox() {
      const { length } = this.selectedRows;
      const { length: mLength } = this.filteredMembers;
      return !!length && length === mLength;
    },
    indeterminate() {
      if (this.checkbox) return false;

      const { length } = this.selectedRows;
      const { length: mLength } = this.filteredMembers;
      return length > 0 && length !== mLength;
    },
    items() {
      const ids = this.selectedRows?.map(m => m.userId) || [];
      let disabled1 = !this.selectedRows.length;
      let disabled2 = !this.selectedRows.length;
      if (!this.tab) {
        disabled1 = !this.selectedRows.find(m => m.joinStatus == "INVITED");
        disabled2 = !this.selectedRows.find(m => m.joinStatus == "PENDING");
      }

      return [
        {
          title: "알림 재전송",
          showItem: !this.tab || this.tab == 2,
          disabled: disabled1,
          click: () => this.resendInvitation(ids)
        },
        {
          title: "승인",
          showItem: !this.tab || this.tab == 3,
          disabled: disabled2,
          click: () => this.approveProjectMembers(ids)
        },
        {
          title: "삭제",
          disabled: !this.selectedRows.length,
          click: () => this.deleteProjectMembers(ids)
        }
      ];
    }
  },
  methods: {
    ...mapActions("flowProjects", [
      "resendInvitation",
      "approveProjectMembers",
      "deleteProjectMembers"
    ]),
    check(checked) {
      if (!checked) {
        return this.$emit("update:selectedRows", []);
      }

      const { value: joinStatus } = this.tabs[this.tab];
      let members = this.members.map(m => ({ ...m }));
      if (joinStatus !== "ALL") {
        members = members.filter(m => m.joinStatus === joinStatus);
      }
      this.$emit("update:selectedRows", members);
    }
  }
};
</script>
