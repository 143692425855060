<template>
  <div>
    <Toolbar v-bind.sync="$data" />
    <TabItems v-bind="$data" v-on="listeners" />
    <Tabs v-bind="$data" v-on="listeners" />
  </div>
</template>

<script>
import Toolbar from "./Toolbar.vue";
import TabItems from "./TabItems.vue";
import Tabs from "./Tabs.vue";
import { mapGetters } from "vuex";

export default {
  components: { Toolbar, TabItems, Tabs },
  data() {
    return {
      tab: 0,
      tabs: [
        { value: "ALL", text: "전체" },
        { value: "ACCEPTED", text: "구성원" },
        { value: "INVITED", text: "초대 관리" },
        { value: "PENDING", text: "참여 신청 관리" }
      ],
      selectedRows: []
    };
  },
  computed: {
    ...mapGetters("flowProjects", ["managedMembers"]),
    listeners() {
      return {
        "click:row": this["click:row"],
        "change:tab": this["change:tab"]
      };
    }
  },
  watch: {
    managedMembers(members) {
      for (let i = this.selectedRows.length - 1; i >= 0; i -= 1) {
        const m = this.selectedRows[i];
        const member = members.find(m2 => m2.userId === m.userId);
        if (!member) {
          this.selectedRows.splice(i, 1);
        } else {
          this.$set(this.selectedRows, i, { ...member });
        }
      }
    }
  },
  methods: {
    "change:tab"(tab) {
      this.selectedRows = [];
      this.tab = tab;
    },
    "click:row"(row) {
      const { userId } = row || {};
      if (!row || !userId) return;

      const member = this.selectedRows.find(m => m.userId === userId);
      if (!member) {
        return this.selectedRows.push(row);
      }

      const selectedRows = this.selectedRows.filter(m => m.userId !== userId);
      this.selectedRows = [...selectedRows];
    }
  }
};
</script>
